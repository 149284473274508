import sanofi from "../assets/images/icon.png";
import regeneron from "../assets/images/R.png";
import external from "../assets/images/key.png";

export interface ButtonGroup {
  id: number;
  title: string;
  className?: string;
  img: string;
  url?: string;
}
export const buttons: ButtonGroup[] = [
  {
    id: 1,
    title: "Sanofi Employee Access",
    img: sanofi,
    url: "https://dupilumab.app.bigtincan.com",
    className:"employee"
  },
  {
    id: 2,
    title: "Regeneron Employee Access",
    img: regeneron,
    url: "https://dupilumab-knowledge-regn-okta.app.bigtincan.com",
    className: "regeneron",
  },
  {
    id: 3,
    title: "Medical Agency Access",
    img: external,
    className: "medical",
    url: "https://dupilumab.app.bigtincan.com",
  },
];
