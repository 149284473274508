import React from "react";
import SSOLogin from "../SSOLogin/SSOLogin";
import styles from "./App.module.scss";
function App() {
  return (
    <div className={styles.home}>
      <SSOLogin />
    </div>
  );
}

export default App;
