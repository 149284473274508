import * as React from 'react';
import Button from '../shared/Button';
import Login from '../shared/Login';
import { buttons } from '../../constants/button';

const SSOLogin = () => {
  const handleOnClick = (url?: string) => () => {
    if (url) {
      window.open(url);
    }
  };
  return (
    <>
      <Login>
        {buttons.map((item) => (
          <Button
            key={item.id}
            title={item.title}
            url={item.img}
            {...(item.className && { className: item.className })}
            onClick={handleOnClick(item.url)}
          />
        ))}
      </Login>
    </>
  );
};
export default SSOLogin;
