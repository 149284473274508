import * as React from 'react'
import cx from 'classnames'
import styles from './Login.module.scss'
import { ReactComponent as Logo } from '../../../assets/images/logo.svg'
import footer from '../../../assets/images/footer.png'

type TProps = {
  className?: string
}
const Login = ({ className, children }: React.PropsWithChildren<TProps>) => {
  return (
    <div className={cx(styles.login, styles[`login-${className}`])}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
        <div className={styles.button}>{children}</div>
      </div>
      <div className={styles.info}>
        <p>
          All information and materials housed on the Dupilumab Knowledge Center
          Website are confidential and are provided for internal Sanofi and
          Regeneron global and local access and/or use only. Please note that
          the information on this website has not been reviewed/approved for
          external use and not all the information contained herein may be
          appropriate for external communication. If you intend to adapt the
          information on this website for an external presentation or slide
          deck, it is imperative that you consult with and seek approval from
          your local review team, who will be in a position to advise you on
          what can be communicated based on the regulatory status of the product
          in your country, the local label, and local rules around promotional
          and non-promotional dissemination of information.
        </p>
      </div>
      <div className={styles.footer}>
        <div className={styles['footer-content']}>
          <img alt='' src={footer} />
          <p>For internal use only.</p>
        </div>
        <div className={styles.copyright}>
          <p>© {new Date().getFullYear()} Sanofi and Regeneron</p>
        </div>
      </div>
    </div>
  )
}
export default Login
