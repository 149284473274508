import * as React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';

type TProps = {
  onClick?: () => void;
  className?: string;
  url?: string;
  title: string;
};
const Button = ({
  className,
  onClick,
  url,
  title,
}: React.PropsWithChildren<TProps>) => {
  return (
    <div className={cx(styles.wrapper, styles[`wrapper-${className}`])}>
      <button
        className={cx(styles.button, styles[`button-${className}`])}
        type="button"
        onClick={onClick}
      >
        {url && (
          <div className={cx(styles.image, styles[`image-${className}`])}>
            <img alt="" src={url} />
          </div>
        )}

        {title}
      </button>
    </div>
  );
};

export default Button;
